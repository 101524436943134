import { dvSrv, SessionContext } from "../App"
import { Accounts } from "../model/Account"
import { Link } from "react-router-dom"
import { useContext, useEffect, useState } from "react"
import axios from "axios"

import './css/NotificationBoard.css'

// export const Dashboard = () => {
//   const {data} = useContext(SessionContext)

//   if (data && data.AccountID === 1)
//     return <Accounts />
//   else if (data && data.IsRoot)
//     return <TeamDataTree />
//   else 
//     return <MyVideos />
// }

export const NotificationBoard = () => {
  const session = useContext(SessionContext)
  const [numPreload, setNumPreload] = useState(0)
  const [numMissingReport, setNumMissingReport] = useState(0)
  const [numActiveTask, setNumActiveTask] = useState(0)

  const [flag, setFlag] = useState(false)
  //const refresh = () => setFlag(!flag)
  useEffect(() => {
    if (!session || !session.data || session.data.AccountID ===1){
      return
    }
    fetchPreload()
    fetchMissingReport()
    fetchTasks()
}, [])

  const fetchPreload =() =>{
    axios.get(dvSrv + `/api/preload/self/all`, {withCredentials: true})
    .then(({data}) => {
      //setData(data)
      setNumPreload(data.filter(video => !video.MarkDelete).length)
    })
    .catch(error => {
      if (error.response && error.response.status === 401) {
        session.setData(null)
      } else { console.error("Error: ", error) }
    })
  }

  const fetchTasks =() =>{
    let category = session.data.IsRoot ? "all" 
      : (session.data.IsAdmin ? "team" : "self")
    axios.get(dvSrv + `/api/task/`+category, {withCredentials: true})
    .then(({data}) => {
      setNumActiveTask(data.filter(task => !task.Done).length)
    })
    .catch(error => {
      if (error.response && error.response.status === 401) {
        session.setData(null)
      } else { console.error("Error: ", error) }
    })
  }
  
const fetchMissingReport=() =>{
  axios.get(dvSrv + `/api/video/self/missingreport`, {withCredentials: true})
    .then(({data}) => {
      //setData(data)
      setNumMissingReport(data ? data.length : 0)
    })
    .catch(error => {
      if (error.response && error.response.status === 401) {
        session.setData(null)
      } else { console.error("Error: ", error) }
    })
}


  if (session?.data?.AccountID === 1)
    return <Accounts />
  else if (session?.data)
    return (
      <div className="notificationboard">
        <h1>Dashboard</h1>
        <div className="notifications mb-4">
          <Link to={`/upload`}  className="link-no-underline">
            <NotificationCard type="Uploads without Case #" count={numPreload} />
          </Link>
        </div>
        <div className="notifications mb-4">
          <Link to={session.data.IsRoot ? `/team/admin` : `/video/`}  className="link-no-underline">
            <NotificationCard type="Uploads missing Reports" count={numMissingReport} />
          </Link>
        </div>
        <div className="notifications mb-4">
          <Link to={`/task/`}  className="link-no-underline">
            <NotificationCard type="Tasks not Completed" count={numActiveTask} />
          </Link>
        </div>
      </div>
    )
}

export const NotificationCard = ({ type, count }) => {
  return (
    <div className="notification-card">
      <span className="notification-type">{type}</span>
      <span className="notification-count">{count}</span>
    </div>
  )
}
