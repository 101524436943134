import styled from "styled-components";
// import css from "styled-jsx/css";
// styled version 6.3 NOT WORKING!!!
// npm i styled-components@5.3.9
// Here is a great example:
// https://medium.com/@jealousgx/build-a-responsive-sidebar-with-react-and-styled-components-b23a39b98a59

const minWidth = "2.8rem" // "5rem"
const fullWidth = "10.3rem" // "15rem"

// Children Component
export const MainPage = styled.div`
  width: 100%;
  height: 100%;
  margin-left: ${props => props.displaysidebar ? fullWidth : minWidth};
  padding: var(--main-container-padding);
  @media (max-width: 468px) {
    margin-left: ${minWidth};
  }
`;

export const SidebarWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;
`;
export const SidebarLogoWrapper = styled.div`
  padding: 0.5rem 1.5rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: ${props => props.displaysidebar ? "space-between" : "center"};
  align-items: center;
  @media (max-width: 468px) {
    justify-content: center;
  }
`;
export const SidebarLogo = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media (max-width: 468px) {
    display: none;
  }
`;
export const SidebarBrand = styled.span`
  display: ${props => props.displaysidebar ? "block" : "none"};
`;
export const SidebarToggler = styled.button`
  cursor: pointer;
  display: ${props => props.displaysidebar ? "block" : "none"};
  @media (max-width: 468px) {
    display: block;
  }
`;

// SidebarItem styles
export const ItemsList = styled.ul`
  list-style: none;
  padding: 0;
`;
export const ItemContainer = styled.li`
  margin-top: 0rem;
  width: 100%;
  // padding: 0.5rem 1.5rem;
  border-radius: 0.2rem;
  cursor: pointer;
  &:hover {
    background: #eaeced;
  }
  &.active {
    background-color: #dbe4f3;
  }
  & a {
    text-decoration: none;
  }
`;
export const ItemWrapper = styled.div`
  display: flex;
  // padding: 5px;
  align-items: center;
  color: #7c7788;
  &:before {
    content: '${props => props.icon}'; 
    font: normal 1.5rem fontawesome;
    // font-weight: 400;
    color: black;
    // top: 2px;
    left: 10px;
  }
`;
export const ItemName = styled.span`
  margin-left: ${props => props.displaysidebar ? "1.2rem" : "0"};
  display: ${props => props.displaysidebar ? "block" : "none"};
  font-size: 1rem;
  font-weight: 600;
  text-transform: capitalize;
`;

// Sidebar Container
export const SidebarContainer = styled.div`
  position: absolute;
  left: 0;
  width: ${props => props.displaysidebar ? fullWidth : minWidth};
  height: calc(100% - 3.5rem);
  padding: 0rem;
  background: #f3f4f4;
  transition: width 350ms ease;
  border-right: 1px solid #d4d8dd;
  overflow-x: hidden;
  ${props => props.displaysidebar && "box-shadow: 8px 0px 12px 0px rgba(0,0,0,0.1)"};
  ${ItemWrapper} {
    justify-content: ${props => !props.displaysidebar && "center"};
  }
  ${ItemContainer} {
    padding: ${props => props.displaysidebar ? "0.5rem 1.5rem;" : "1.25rem 3rem;"};
  }

  ::-webkit-scrollbar {
    width: 4px;
    height: 3px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #eaeced;
    &:hover {
      background: #d5e0f3;
    }
  }
  @media (max-width: 468px) {
    width: ${minWidth};
  }
`;

export const SidebarContainerHoverable = styled(SidebarContainer)`
  &:hover {
    ${props => !props.displaysidebar && "box-shadow: 8px 0px 12px 0px rgba(0,0,0,0.1)"};

    @media (min-width: 468px) {
      width: ${props => !props.displaysidebar && fullWidth};

      ${SidebarLogoWrapper} {
        justify-content: ${props => !props.displaysidebar && "space-between"};
      }
      ${SidebarBrand} {
        display: ${props => !props.displaysidebar && "block"};
      }
      ${SidebarToggler} {
        display: ${props => !props.displaysidebar && "block"};
      }
      ${ItemWrapper} {
        justify-content: ${props => !props.displaysidebar && "flex-start"};
      }
      ${ItemName} {
        display: ${props => !props.displaysidebar && "block"};
        margin-left: ${props => !props.displaysidebar && "0.5rem"};
      }
    }
  }
`;