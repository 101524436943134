import axios from "axios"
import { useContext, useEffect, useState } from "react"
import { dvSrv } from "../App"
import { Link, useParams } from "react-router-dom"
import ReactJson from "@microlink/react-json-view"
import { SessionContext } from "../App"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faPeopleGroup, faUser } from "@fortawesome/free-solid-svg-icons"
import { faCopy, faEyeSlash } from "@fortawesome/free-regular-svg-icons"
import { toast } from "react-toastify"
import { Tooltip } from "react-tooltip"
import { useTeamAndUser, useTeamAndAllUsers } from "../hook/DataProvider"
import { MFASetup } from "../MFA"
import { TimeStringForTimeZone } from "./Setting"
import { Button, Modal } from "react-bootstrap"
import DateTimePicker from "react-datetime-picker"

export const Users = () => {
  const [teams, users] = useTeamAndUser()
  return (
    <>
    <h2>Users</h2>
    <UserTable users={users} teams={teams} />
    </>
  )
}

export const UserTable = ({users, teams, refresh}) => {
  return (
    <table class="table table-hover">
      <thead>
        <th>First Name</th>
        <th>Last Name</th>
        <th>Username</th>
        <th>Team</th>
        {/* <th class="text-center">Root</th> */}
        <th class="text-center">TeamAdmin</th>
        {/* <th class="text-center">IP Restriction</th> */}
        <th class="text-center">Mobile Access</th>
        <th class="text-center">Web Access</th>
        <th class="text-center">Edit Report</th>
        <th class="text-center">Edit Note</th>
        <th class="text-center">Edit Video</th>
        <th class="text-center">Last Sign-in</th>
        <th class="text-center">Status</th>
        <th class="text-center">Action</th>
      </thead>
      <tbody>
        {users && users.map(user => <UserTab user={user} teams={teams} refresh={refresh} />)}
      </tbody>
    </table>
  )
}

export const UserTab = ({user, teams, refresh}) => {
  const session = useContext(SessionContext)
  const [data, setData] = useState(user)
  const [edit, setEdit] = useState(false)

  const handleEdit = (e) => {
    setEdit(!edit)
  }

  const handleSelect = (e) => {
    setData({...data, ["TeamID"]: parseInt(e.target.value)})
  }

  const handleErrorResponse = (error) => {
    if (error.response.status === 401) {
      session.setData(null)
    } else {console.error("Error: ", error)}
  }

  const handleAssign = () => {
    axios.post(dvSrv + `/api/user/${data.ID}`, {
      ID: data.ID,
      TeamID: data.TeamID,
    }, {withCredentials: true})
    .then(({data}) => {
      setData(data)
      setEdit(false)
      if (refresh) refresh()
    })
    .catch(error => handleErrorResponse(error))
  }

  const handleDisable = () => {
    axios.put(dvSrv + `/api/user/${data.ID}`, {
      ID: data.ID,
      Disabled: !data.Disabled,
    }, {withCredentials: true})
    .then(({data}) => {
      setData(data)
    })
    .catch(error => handleErrorResponse(error))
  }

  const handleValueChange = (field, val) => {
    axios.put(dvSrv + `/api/user/${data.ID}`, {
      ID: data.ID,
      [field]: val,
    }, {withCredentials: true})
    .then(({data}) => {
      setData(data)
    })
    .catch(error => handleErrorResponse(error))
  }

  if (data)
  return (
    <>
    <tr>
      <td>{data.FirstName}</td>
      <td>{data.LastName}</td>
      <td>{data.username}</td>
      {!edit
      ? data.TeamID 
        ? <td>{teams.find(t => t.ID === data.TeamID).Name}</td>
        : <td class="text-decoration-line-through">Not assigned</td>
      : <td>
          <div class="input-group">
            <div class="input-group-text">
            Team
            </div>
            <select class="form-select form-select-sm" aria-label=".form-select-sm"
              name="teamAssigned"
              value={data.TeamID || ""}
              onChange={handleSelect}>
                <option selected>Pick a team</option>
                {teams.map(team => <option value={team.ID}>{team.Name}</option>)}
            </select>
            <button class="btn btn-sm btn-warning" type="button" onClick={handleAssign}>Assign</button>
          </div>
        </td>
      }
      {/* <td class="text-center"><input class="form-check-input mt-0" type="checkbox" checked={data.IsRoot}  
        onChange={(e) => handleValueChange("IsRoot", e.target.checked)} disabled={data.ID === session.data.ID}/></td> */}
      <td class="text-center"><input class="form-check-input mt-0" type="checkbox" checked={data.IsAdmin}  
        onChange={(e) => handleValueChange("IsAdmin", e.target.checked)} disabled={data.IsRoot}/></td>
      {/* <td class="text-center">{data.IPAccess}</td> */}
      <td class="text-center"><input class="form-check-input mt-0" type="checkbox" checked={data.MobileAccess} 
        onChange={(e) => handleValueChange("MobileAccess", e.target.checked)} disabled={data.ID === session.data.ID}/></td>
      <td class="text-center"><input class="form-check-input mt-0" type="checkbox" checked={data.WebAccess}  
        onChange={(e) => handleValueChange("WebAccess", e.target.checked)} disabled={data.ID === session.data.ID}/></td>
      <td class="text-center"><input class="form-check-input mt-0" type="checkbox" checked={data.ReportEdit}  
        onChange={(e) => handleValueChange("ReportEdit", e.target.checked)}/></td>
      <td class="text-center"><input class="form-check-input mt-0" type="checkbox" checked={data.NoteEdit}  
        onChange={(e) => handleValueChange("NoteEdit", e.target.checked)}/></td>
      <td class="text-center"><input class="form-check-input mt-0" type="checkbox" checked={data.VideoEdit}  
        onChange={(e) => handleValueChange("VideoEdit", e.target.checked)}/></td>
      <td class="text-center">{data.LastSignedIn && TimeStringForTimeZone(session, data.LastSignedIn)}</td>
      <td class="text-center">{data.Disabled ? <span class="text-decoration-line-through">Disabled</span> : "Active"}</td>
      <td class="text-center">
        <button class="btn btn-sm btn-outline-secondary" onClick={handleEdit} disabled={data.ID === session.data.ID}>{edit ? "Cancel" : "Edit"}</button>
        <button class="btn btn-sm btn-outline-secondary" onClick={handleDisable} disabled={data.ID === session.data.ID}>{data.Disabled ? "Enable" : "Disable"}</button>
        <ResetUserPasswordButton user={data} />
      </td>
    </tr>
    {/* <ReactJson src={{data}} /> */}
    </>
  )
}

// This should only be used by root user, and the api call is limited to root.
export const ResetUserPasswordButton = ({user}) => {
  const session = useContext(SessionContext)
  const handleResetPassword = () =>{
    const npass = window.prompt("Set New Password for User: " + user.username)
    if (npass !== null && (npass === "" || npass.length < 6)){
      alert("The password has to be at least 6 characters long!")
      return
    }
    if (npass){ // in case prompt Cancelled, npass will be null
      axios.post(dvSrv + `/api/user/${user.ID}/resetpassword`, {
        NewPassword: npass,
      }, {withCredentials: true})
      .then(({data}) => {
        //setData(data)
      })
      .catch(error => {if (error.reponse && error.response.status === 401) {
        session.setData(null)
      } else {console.error("Error: ", error)}})
    } 
  }

  if (user){
  return (
    <button class="btn btn-sm btn-outline-secondary" onClick={handleResetPassword}>Reset Password</button>
  )}
}

// export const UserCard = ({user}) => {
//   return (
//   <div class="card mb-3" style={{maxWidth: "450px"}}>
//     <div class="row g-0">
//       <div class="col-md-3">
//         <img src="http://placehold.it/200x320" class="img-fluid rounded-start" alt="..." />
//       </div>
//       <div class="col-md-9">
//         <div class="card-body">
//           <Link to={`/user/${user.ID}`}>
//           <h5 class="card-title">{user.FirstName} {user.LastName}</h5>
//           </Link>
//           <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content.</p>
//           <p class="card-text"><small class="text-muted">Last sign in: {TimeStringForTimeZone(session, user.LastSignin)}</small></p>
//         </div>
//       </div>
//     </div>
//   </div>
//   )
// }

export const User = () => {
  const session = useContext(SessionContext)

  const {id} = useParams()
  const [user, setUser] = useState()

  useEffect(() => {
  axios.get(dvSrv + `/api/user/${id}`, {withCredentials: true})
  .then(({data}) => setUser(data))
  .catch(error => {
    if (error.response.status === 401) {
      session.setData(null)
    } else { console.error("Error: ", error) }
  })}, [])

  if (user) {
  return (
  <>
    <div>
      <h1>{user.Name}</h1>
      <h2>User Profile</h2>
        {user.username}
    </div>
    {/* <ReactJson src={{user}} collapsed="1" /> */}
  </>
  )}
}

export const UserTabSimple = ({user, level, resetPWD}) => {
  return (
    <tr>
      <td colSpan={5} style={{textIndent: `60px`}}>
        <FontAwesomeIcon icon={faUser} /> &nbsp;
        {user.FirstName} {user.LastName} | {user.username}
      </td>
      {resetPWD && <td colSpan={5}>
        <ResetUserPasswordButton user={user} />
      </td>}
     
    </tr>
  )
}

export const UserProfile = () => {
  const session = useContext(SessionContext)
  const [data, setData] = useState()
  const [mfaEnabled, setMfaEnabled] = useState(false)

  useEffect(() => {
    axios.get(dvSrv + "/api/user/self", {withCredentials: true})
    .then(({data}) => {
      setData(data)
      setMfaEnabled(data.MFAEnabled)
    })
    .catch(error => {
      if (error.response.status === 401) {
        session.setData(null)
      } else { console.error("Error: ", error) }
    })
  }, [])

  const onNameChange = (e) => {
    const {name, value} = e.target
    setData({...data, [name]: value})
  }

  const onNameSave = () => {
    axios.put(dvSrv + `/api/user/self`, data, {withCredentials: true})
    .then(({data}) => {
      setData(data)
      toast.success("Name has been changed successfully.")
    })
    .catch(error => {
      if (error.response.status === 401) {
        session.setData(null)
      } else {
        console.error("Error: ", error)
        toast.error(error.response.data.error)
      }
    })
  }

  const handleMFAResult = (success)=>{
      setMfaEnabled(success)
  }

  const handleMFAEnabled = (enabled) =>{

    axios.post(dvSrv + `/api/mfa/enable`, {
      MFAEnabled: enabled,
    }, {withCredentials: true})
    .then(({data}) => {
//      setData(data)
      setMfaEnabled(enabled)
      toast.success("MFA status changed.")
    })
    .catch(error => {
      if (error.response.status === 401) {
        setMfaEnabled(!enabled)
        session.setData(null)
      } else {
        console.error("Error: ", error)
        toast.error(error.response.data.error)
      }
    })
  }

  if(data)
  return (
    <>
    <h2>Your Profile</h2>

    <div class="my-2 row">
      <label for="staticEmail" class="col-sm-2 col-form-label">Username</label>
      <div class="col-sm-6">
        <input type="text" readonly class="form-control-plaintext" id="staticEmail" value={data.username} />
      </div>
    </div>
    <div class="mb-2 row">
      <label for="team" class="col-sm-2 col-form-label">Team</label>
      <div class="col-sm-6">
        <input type="text" readonly class="form-control-plaintext" id="team" value={data.Team.Name} />
      </div>
    </div>
    <div class="mb-2 row">
      <label for="last" class="col-sm-2 col-form-label">Last Signed-in</label>
      <div class="col-sm-6">
        <input type="text" readonly class="form-control-plaintext" id="last" value={TimeStringForTimeZone(session, data.LastSignedIn)} />
      </div>
    </div>

    <h4 class="mt-5 mb-3">Change Name</h4>
    <div class="mb-2 row">
      <label for="firstname" class="col-sm-2 col-form-label">First Name</label>
      <div class="col-sm-6">
        <div class="input-group">
        <input type="text" class="form-control" name="FirstName" id="firstname" value={data.FirstName} onChange={onNameChange} />
        <button type="submit" class="btn btn-outline-secondary" onClick={onNameSave}>Submit</button>
        </div>
      </div>
    </div>
    <div class="mb-2 row">
      <label for="lastname" class="col-sm-2 col-form-label">Last Name</label>
      <div class="col-sm-6">
        <div class="input-group">
        <input type="text" class="form-control" name="LastName" id="lastname" value={data.LastName} onChange={onNameChange} />
        <button type="submit" class="btn btn-outline-secondary" onClick={onNameSave}>Submit</button>
        </div>
      </div>
    </div>

    <h4 class="mt-5 mb-3">Change Password</h4>
    {data && data.NumSignedIn === 1 && 
    <span class="badge rounded-pill bg-warning fs-6 text-dark mb-2">This is the first time you signed in, please RESET your initial password!</span>}
    <PasswordChanger username={data.username} />

    <h4 class="mt-5 mb-3">MFA</h4>
    <div class="form-check text-start my-3">
      <input class="form-check-input" type="checkbox" id="flexCheckDefault" checked={mfaEnabled} onChange={()=>handleMFAEnabled(!mfaEnabled)} />
      <label class="form-check-label" for="flexCheckDefault">Enable MFA</label>
    </div>
    {mfaEnabled && !data.MFASetup && <MFASetup handleMFAResult={handleMFAResult} />}

    <Tooltip anchorSelect="#show-password" place="top">Show password</Tooltip>
    <Tooltip anchorSelect="#copyPassword" place="top">Copy password to clipboard</Tooltip>

    {/* <ReactJson src={{mfaEnabled, data}} /> */}
  </>
  )
}

export const PasswordChanger = ({username}) => {
  const session = useContext(SessionContext)
  const [password, setPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPassword2, setNewPassword2] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  
  const validPassword = 
    newPassword === newPassword2 
    && newPassword.length > 5

  const randomPassword = () => {
    let password = GeneratePassword(8);
    setNewPassword(password);
    setNewPassword2(password)
  }

  const copyPassword = () => {
    if (newPassword) {
      navigator.clipboard.writeText(newPassword);
      toast.success("Password copied to the clipboard.")
      // setShowModal(true);
      // setTimeout(() => {
      //   setShowModal(false);
      // }, 3000);
    }
  }

  const changePassword = () => {
    axios.post(dvSrv + `/api/changepassword`, {
      Username: username,
      Password: password,
      NewPassword: newPassword,
    }, {withCredentials: true})
    .then(({data}) => {
      toast.success("Password has been reset successfully.")
    })
    .catch(error => {
      if (error.response.status === 401) {
        session.setData(null)
      } else {
        console.error("Error: ", error)
        toast.error(error.response.data.error)
      }
    })
  }

  return (
    <>
    <div class="mb-2 row">
      <label for="currentPassword" class="col-sm-2 col-form-label">Current Password</label>
      <div class="col-sm-6">
        <input type={showPassword ? "text" : "password"} class="form-control" placeholder="Please enter your current password (required)" id="currentPassword" value={password} onChange={e => setPassword(e.target.value)} />
      </div>
    </div>
    <div class="mb-2 row">
      <label for="inputPassword" class="col-sm-2 col-form-label">New Password</label>
      <div class="col-sm-6">
        <div class="input-group">
          <input type={showPassword ? "text" : "password"} class="form-control" placeholder="Please enter your new password" id="inputPassword" value={newPassword} onChange={e => setNewPassword(e.target.value)}/>
          <button class="btn btn-outline-secondary" type="button" id="show-password" onClick={()=>setShowPassword(!showPassword)}>
            {showPassword 
            ? <FontAwesomeIcon icon={faEyeSlash} />
            : <FontAwesomeIcon icon={faEye} />
          }
          </button>
          <button class="btn btn-outline-secondary" onClick={randomPassword} type="button">Auto-Generate</button>
          <button class="btn btn-outline-secondary" onClick={copyPassword} type="button" id="copyPassword" disabled={!newPassword2}><FontAwesomeIcon icon={faCopy} /> Copy</button>
          </div>
      </div>
    </div>
    <div class="mb-2 row">
      <label for="inputPassword2" class="col-sm-2 col-form-label">Repeat New Password</label>
      <div class="col-sm-6">
        <div class="input-group">
          <input type={showPassword ? "text" : "password"} class="form-control" placeholder="Please repeat your new password" id="inputPassword2" value={newPassword2} onChange={e => setNewPassword2(e.target.value)}/>
          <button type="submit" class="btn btn-outline-secondary" onClick={changePassword} disabled={!password || !validPassword}>Submit</button>
        </div>
      {newPassword !== newPassword2 && "password do not match"}
      </div>
    </div>
    </>
  )
}

export const GeneratePassword = (length) => {
  const useUppercase = 1 //document.getElementById("uppercase").checked;
  const useLowercase = 1 //document.getElementById("lowercase").checked;
  const useNumbers = 1 //document.getElementById("numbers").checked;
  const useSymbols = 1 //document.getElementById("symbols").checked;

  let charset = "";
  if (useUppercase) charset += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  if (useLowercase) charset += "abcdefghijklmnopqrstuvwxyz";
  if (useNumbers) charset += "0123456789";
  if (useSymbols) charset += "!@#$%^&*";

  let password = "";
  for (let i = 0; i < length; i++) {
    password += charset.charAt(Math.floor(Math.random() * charset.length));
  }

  return password
}

export const UserNotifyModal = ({show, setShow, caseNum}) => {
  
  const [teams, users] = useTeamAndAllUsers()
  // State to keep track of checked rows
  const [checkedState, setCheckedState] = useState([])
  const [emailBody, setEmailBody] = useState('')
  const [dueDate, setDueDate] = useState()

  useEffect(() => {
    setCheckedState(new Array(users.length).fill(false))
    }, [users])

  const onHide = () => setShow(false)
  const handleSubmit = () => {
    const selectedUserIDs = users
      .filter((user, index) => checkedState[index]) // Filter selected users
      .map(user => user.ID) // Extract the ID of each selected user
//      .join(','); // Join IDs into a string

    if (selectedUserIDs.length === 0) {
      alert('No users selected!')
      return
    }
    axios.post(dvSrv + `/api/task/notify`,
      {
      Cases: new Array(1).fill(caseNum),
      UserIDs: selectedUserIDs,
      EmailBody: emailBody,
      DueDate: dueDate,
      },
      {withCredentials: true})
      .then(({}) => {
        toast.success("Notifications sent successfully")
      })
      .catch((error) => {
        toast.error(error.response.data.error)
      })
    setShow(false)
  }
  const handleDateTime = (date) => {
    // assign the date-time from picker directly,
    // otherwise the picker clock time may not be the same with input
    // due to time zone conversion.
    // The DateTimePicker seems ALWAYS to use local time.
    setDueDate(date)
  }
  return (
    <Modal show={show} onHide={onHide} centered={true}>
      <div className="modal-content">
      <Modal.Header closeButton>
        <Modal.Title>Select users to notify on case #{caseNum}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <UserSelectorTable users={users} teams={teams} 
        checkedState={checkedState} setCheckedState={setCheckedState}/>
        <textarea class="form-control mb-3" placeholder="Email Message" 
            style={{ height: '75px' }}
            onChange={e => setEmailBody(e.target.value)}
            id="notifyEmailBody" >
        </textarea>
        <div class="row">
          <div class="col-4"><label for="taskDueDate">Due Date</label></div>
          <div class="col-8"><DateTimePicker id="taskDueDate" onChange={handleDateTime} value={dueDate}/></div>
        </div>

      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSubmit}>Notify</Button>
        <Button variant="secondary" onClick={onHide}>Cancel</Button>
      </Modal.Footer>
      </div>
    </Modal>
  )
}

export const UserSelectorTable = ({users, teams, checkedState, setCheckedState}) => {

    // Handler for changing the header checkbox
    const handleHeaderCheckboxChange = (event) => {
      setCheckedState(new Array(users.length).fill(event.target.checked));
    };
  
    // Handler for changing individual row checkboxes
    const handleRowCheckboxChange = (position) => {
      const updatedCheckedState = checkedState.map((item, index) =>
        index === position ? !item : item
      );
  
      setCheckedState(updatedCheckedState);
    };  
  
  return (
    <>
    <table class="table table-hover">
      <thead>
        <th>Full Name</th>
        <th>Username</th>
        <th>Team</th>
        <th class="text-center">
          <input
              type="checkbox"
              onChange={handleHeaderCheckboxChange}
              checked={checkedState.length>0 && checkedState.every(Boolean)}
            />
        </th>
      </thead>
      <tbody>
        {users && users.map((user, index) => 
          <UserSelectorTab user={user} index={index} checked={checkedState[index]} 
          teams={teams} handleRowCheckboxChange={handleRowCheckboxChange}/>
        )}
      </tbody>
    </table>
    {/* <ReactJson src={{checkedState}} /> */}
    </>
  )
}

export const UserSelectorTab  = ({user, index, checked, teams, handleRowCheckboxChange}) => {
  return(
    <tr>
      <td>{user.FirstName} {user.LastName}</td>
      <td>{user.username}</td>
      {user.TeamID 
        ? <td>{teams.find(t => t.ID === user.TeamID).Name}</td>
        : <td class="text-decoration-line-through">Not assigned</td>}
      <td>
        <input
          type="checkbox"
          checked={checked}
          onChange={() => handleRowCheckboxChange(index)}
        />
      </td>
    </tr>
  )
}

// Common convenient function
export const GetUserFullname = (uid, users) => {
    const user = uid && users && users.find((item) => item.ID === uid)
    return user ? user.FirstName + " " + user.LastName : ""
}
