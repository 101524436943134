// https://fontawesome.com/v4/cheatsheet/
const shareRoutes = [
  // { name: "Resources"},
  { id: 51, path: "/tutorial", name: "Tutorial", ico: "\f02d" },
]

const userRoutes = [
  { name: ""},
  { id: 1, path: "/dashboard", name: "Dashboard", ico: "\f0e4" },
  { id: 2, path: "/video/", name: "My Videos", ico: "\f03d" },
  { id: 3, path: "/upload", name: "Upload", ico: "\f093" },
  { id: 4, path: "/task/", name: "My Tasks", ico: "\f0ae" },
]  

const teamAdminRoutes = [
  // { path: "/dashboard/", name: "Dashboard", exact: true, ico: "\f00a" },
  { name: ""},
  { id: 11, path: "/dashboard", name: "Dashboard", ico: "\f0e4" },
  { id: 12, path: "/team/", name: "My Team", ico: "\f0f9" },
  { id: 13, path: "/video/", name: "My Videos", ico: "\f03d" },
  { id: 14, path: "/upload", name: "Upload", ico: "\f093" },
  { id: 15, path: "/task/", name: "Tasks", ico: "\f0ae" },
  // { path: "/video/", name: "Favorites", ico: "\f005" },
  // { name: ""},
  // { name: "My Tasks"},
  // { path: "/video/drafted", name: "Drafts", ico: "\f044" },
  // { path: "/video/", name: "To Respond", ico: "\f01c" },
  // { name: ""},
]

const rootRoutes = [
  { id: 21, name: ""},
  { id: 22, path: "/dashboard", name: "Dashboard", ico: "\f00a" },
  { id: 23, path: "/team/admin", name: "Teams & Videos", ico: "\f0e8" },
  { id: 24, path: "/user/", name: "Users", ico: "\f2be" },
  { id: 25, path: "/upload", name: "Upload", ico: "\f093" },
  { id: 26, path: "/report/", name: "Reports", ico: "\f080" },
  { id: 27, path: "/task/", name: "Tasks", ico: "\f0ae" },
  { id: 28, path: "/setting", name: "Admin Settings", ico: "\f085" },
  // { id: 29, path: "/billing", name: "Billing", ico: "\f0d6" },
  // { path: "/", name: "Analysis", ico: "\f024" },
  // { path: "/", name: "Invoice", ico: "\f01c" },
  // { path: "/", name: "Orders", ico: "\f005" },  
]

// console.log("REACT_APP_ENABLE_STRIPE: ", `${process.env.REACT_APP_ENABLE_STRIPE}`)
// // Conditionally add the Subscription route
// if (`${process.env.REACT_APP_ENABLE_STRIPE}` === "true") {
// 		rootRoutes.push({id: 29, path: "/billing", name: "Subscription", ico: "\f0d6"})
// }

const deepvinRoutes = [
  { name: "DEEPVIN"},
  { id: 91, path: "/account/", name: "Accounts", ico: "\f015" },
  { id: 92, path: "/invoice/", name: "Invoices", ico: "\f00b" },
  // { path: "/", name: "Users", ico: "\f005" },
]

export const Routes = (data) => data && data.IsRoot
    ? data.AccountID === 1 
      ? [...deepvinRoutes, ...rootRoutes, ...shareRoutes]
      : [...rootRoutes, ...shareRoutes]
    : data && data.IsAdmin 
      ? [...teamAdminRoutes, ...shareRoutes]
      : [...userRoutes, ...shareRoutes]
      