import axios from "axios"
import { useContext, useEffect, useState } from "react"
import { dvSrv } from "../App"
import { Link, useParams } from "react-router-dom"
import { UserTabSimple, UserTable } from "./User"
import { VideoTableWithPagination } from "./Video"
import { faFileCsv, faHouse, faMinus, faPeopleGroup, faPlus, faSuitcaseMedical, faUser, faUserPlus, faUserTie, faVideo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { SessionContext } from "../App"
import { Button, Modal } from "react-bootstrap"
import { VideoTable } from "./Video"
import { Devices } from "./Device"
import { faBookmark } from "@fortawesome/free-regular-svg-icons"
import { Tooltip } from "react-tooltip"
import { toast } from "react-toastify"
import ReactJson from "@microlink/react-json-view"

export const GetNodeVideos = (node) => {
  var vs 
  if (node.Videos) vs = [...node.Videos]
  if (node.SubTeams) node.SubTeams.forEach(sub => vs.push(...GetNodeVideos(sub)))
  return vs
}
export const GetNodeUsers = (node) => {
  var us 
  if (node.Users) us = [...node.Users]
  if (node.SubTeams) node.SubTeams.forEach(sub => us.push(...GetNodeUsers(sub)))
  return us
}
export const GetNodeTeam = (node) => {
  var ts = [{ID: node.ID, Name: node.Name}]
  if (node.SubTeams) node.SubTeams.forEach(sub => ts.push(...GetNodeTeam(sub)))
  return ts
}
export const GetNodeParentUsers = (pnode) => {
  var us 
  if (pnode && pnode.Users) us = [...pnode.Users]
  if (pnode && pnode.Parents) pnode.Parents.forEach(p => us.push(...GetNodeParentUsers(p)))
  return us
}
export const GetNodeParentTeam = (pnode) => {
  var ts = [{ID: pnode.ID, Name: pnode.Name}]
  if (pnode.Parents) pnode.Parents.forEach(pn => ts.push(...GetNodeParentTeam(pn)))
  return ts
}

export const TeamDataTree = () => {
  const session = useContext(SessionContext)

  const [teamTree, setTeamTree] = useState({})
  const [account, setAccount] = useState()
  const [cases, setCases] = useState([])
  const [unassigned, setUnassigned] = useState([])
  // const [unassignedUsers, setUnassignedUsers] = useState([])
  const [numVideos, setNumVideos] = useState(0)
  const [idToShown, setIDToShown] = useState()
  const [treeToShown, setTreeToShown] = useState()
  //const [videoPageUrl, setVideoPageUrl] = useState("/api/team/self") 

  const [flag, setFlag] = useState(false)
  const refresh = (id) => {
    setFlag(!flag)
    setEditTeam(false)
    if (id) setIDToShown(id)
  }

  const users = GetNodeUsers(teamTree)
  const teams = GetNodeTeam(teamTree)
  // const unassignedUsers = teamTree && teamTree.Users && teamTree.Users.filter(u => !u.IsRoot)

  const [editTeam, setEditTeam] = useState(false)
  const {id} = useParams() // not used currently
  const {uuid} = useParams() // This is for deepvin only (from <DeepvinOnlyRoute>), uuid is for account

  const isDeepvinAdmin = session.data.AccountID === 1 && uuid && uuid !== session.data.Account.UUID

  useEffect(() => {
    let path = "/api/account/self"
    if (isDeepvinAdmin) {
      path = `/api/account/${uuid}`
    }

    axios.get(dvSrv + path, {withCredentials: true})
    .then(({data}) => setAccount(data))
    .catch(error => {
      if (error.response.status === 401) {
        session.setData(null)
      } else { console.error("Error: ", error) }
    })
  }, [flag])

  useEffect(() => {
    let path = "/api/team/self"
    if (isDeepvinAdmin) {
      path = `/api/account/${uuid}/datatree`
    }

    axios.get(dvSrv + path, {withCredentials: true})
    .then(({data}) => {
      setTeamTree(data)
      if (!idToShown) {
        setIDToShown(data.ID)
      }
    })
    .catch(error => {
      if (error.response.status === 401) {
        session.setData(null)
      } else { console.error("Error: ", error) }
    })}, [flag, unassigned])

  useEffect(() => {
    let videoPageUrl = idToShown ? `/api/team/${idToShown}/datatree` : `/api/team/self`
    axios.get(dvSrv + videoPageUrl, {withCredentials: true})
      .then(({data}) => {
        setTreeToShown(data)
        setNumVideos(GetNodeVideos(data).length)
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          session.setData(null)
        } else { console.error("Error: ", error) }
    })}, [flag, idToShown])
      
  useEffect(() => {
    let path = "/api/video/unassigned"
    if (isDeepvinAdmin) {
      path = path + `?accountid=${uuid}`
    }

    axios.get(dvSrv + path, {withCredentials: true})
    .then(({data}) => setUnassigned(data))
    .catch(error => {
      if (error.response.status === 401) {
        session.setData(null)
      } else { console.error("Error: ", error) }
    })}, [flag])
    
  const [showTeamModal, setShowTeamModal] = useState(false)
  const [showUserModal, setShowUserModal] = useState(false)
  const [showUserImportModal, setShowUserImportModal] = useState(false)
  const handleMergeUp = () => {
    if (window.confirm(`Are you sure you want to delete this team and move its members to ${mergeTeamMsg}?`)) {
      axios.get(dvSrv + `/api/team/${idToShown}/delete`, {withCredentials: true})
      .then(({data}) => toast.success("Team deleted successfully."))
      .catch(error => {
        if (error.response.status === 401) {
          session.setData(null)
        } else if (error.response.data) {
          toast.error(`Error: ${error.response.data.error}`)
        } else { console.error("Error: ", error) }
      })

      refresh()
    } else {}
  }
  const editTeamButton =
    <div class="btn-group">
      <button class="btn btn-sm btn-outline-secondary" id="btn-add-team"  onClick={()=>setShowTeamModal(true)} 
        disabled={treeToShown && treeToShown.IsDefault}><FontAwesomeIcon icon={faPlus} /></button>
      <button type="button" class="btn btn-sm btn-outline-secondary" id="btn-delete-team" onClick={handleMergeUp} 
        disabled={idToShown === teamTree.ID || (treeToShown && treeToShown.IsDefault)}><FontAwesomeIcon icon={faMinus} /></button>
        &nbsp;&nbsp;
      <button class="btn btn-sm btn-outline-secondary" id="btn-add-user" onClick={()=>setShowUserModal(true)}><FontAwesomeIcon icon={faUserPlus} /></button>
      <button class="btn btn-sm btn-outline-secondary" id="btn-import-user" onClick={()=>setShowUserImportModal(true)} 
        disabled={!(treeToShown && treeToShown.Name) || idToShown === teamTree.ID}><FontAwesomeIcon icon={faFileCsv} /></button>
    </div>    

  const mergeTeamMsg = (treeToShown && treeToShown.ParentTeamID === teamTree.ID) ? "General team" : "its parent team"

  if (teamTree)
  return (
    <>
    <div style={{display: 'flex', gap: '10px'}}>
      <div class="pe-0" style={{width: '18rem'}}>
        <table class="table table-hover">
          <thead>
            <tr>
              <th class="bg-info fw-bold"><FontAwesomeIcon icon={faHouse} />&nbsp;&nbsp;{account && account.Name}</th>
            </tr>
          </thead>
          <tbody>
            <TeamTab team={teamTree} level={1} editTeam={editTeam} refresh={refresh} coloredID={idToShown} />
            <tr>
              <td class="text-center">
                {/* <div class="col-4 mb-3"><ExportPDFButton /></div> */}
                {session.data && session.data.IsRoot && editTeamButton}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="" style={{flexGrow: 2}}>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          {treeToShown && <li class="nav-item" role="presentation">
            <button class="nav-link active internal-tab" id="vlist-tab" data-bs-toggle="tab" data-bs-target="#vlist" type="button" role="tab" aria-controls="vlist" aria-selected="false">Team Videos {<span class="badge rounded-pill bg-info">{numVideos}</span>}</button>
          </li>}
          {/* <li class="nav-item" role="presentation">
            <button class="nav-link internal-tab" id="toreview-tab" data-bs-toggle="tab" data-bs-target="#toreview" type="button" role="tab" aria-controls="toreview" aria-selected="false">To Review {videos && <span class="badge rounded-pill bg-warning text-dark">{videos.length}</span>}</button>
          </li> */}
          {session.data && session.data.IsRoot && 
          <>
            <li class="nav-item" role="presentation">
              <button class="nav-link internal-tab" id="unassigned-tab" data-bs-toggle="tab" data-bs-target="#unassigned" type="button" role="tab" aria-controls="unassigned" aria-selected="false">Unclaimed Videos {unassigned && <span class="badge rounded-pill bg-danger">{unassigned.length}</span>}</button>
            </li>
            {/* {unassignedUsers && unassignedUsers.length > 0 &&
            <li class="nav-item" role="presentation">
              <button class="nav-link internal-tab" id="un-users-tab" data-bs-toggle="tab" data-bs-target="#un-users" type="button" role="tab" aria-controls="un-users" aria-selected="false">Unassigned Users <span class="badge rounded-pill bg-danger">{unassignedUsers.length}</span></button>
            </li>} */}
          </>}
          <li class="nav-item" role="presentation">
            <button class="nav-link internal-tab" id="users-tab" data-bs-toggle="tab" data-bs-target="#users" type="button" role="tab" aria-controls="users" aria-selected="false">Team Members</button>
          </li>
          {/* <li class="nav-item" role="presentation">
            <button class="nav-link internal-tab" id="library-tab" data-bs-toggle="tab" data-bs-target="#library" type="button" role="tab" aria-controls="library" aria-selected="false">Library</button>
          </li> */}
          {session.data && (session.data.AccountID === 1 || session.data.IsRoot) && 
          <li class="nav-item" role="presentation">
            <button class="nav-link internal-tab" id="device-tab" data-bs-toggle="tab" data-bs-target="#devices" type="button" role="tab" aria-controls="devices" aria-selected="false">Devices</button>
          </li>}
        </ul>

        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active" id="vlist" role="tabpanel" aria-labelledby="vlist-tab">
            {/* <VideoTable videos={videos} teams={teams} users={users} refresh={refresh} /> */}
            <VideoTableWithPagination apiUrl={idToShown ? `/api/team/${idToShown}/datatree` : `/api/team/self`} refresh={refresh} repaging={flag}/>
          </div>
          {/* <div class="tab-pane fade" id="toreview" role="tabpanel" aria-labelledby="toreview-tab">
            <VideoAssignTable videos={videos} teams={teams} users={users} refresh={refresh} />
          </div> */}
          <div class="tab-pane fade" id="unassigned" role="tabpanel" aria-labelledby="unassigned-tab">
            <VideoTable videos={unassigned} teams={teams} users={users} refresh={refresh} />
          </div>
          {/* {session.data && session.data.IsRoot && unassignedUsers && unassignedUsers.length > 0 &&
          <div class="tab-pane fade" id="un-users" role="tabpanel" aria-labelledby="un-users-tab">
            <UserTable users={unassignedUsers} teams={teams} refresh={refresh} />
          </div>
          } */}
          <div class="tab-pane fade" id="users" role="tabpanel" aria-labelledby="users-tab">
            {treeToShown && treeToShown.Users && treeToShown.Users.map(user => <UserTabSimple user={user} 
            resetPWD={session.data && (session.data.AccountID === 1 || session.data.IsRoot)}/>)}
          </div>
          {session.data && (session.data.AccountID === 1 || session.data.IsRoot) &&
          <div class="tab-pane fade" id="devices" role="tabpanel" aria-labelledby="device-tab">
            <Devices account_uuid ={uuid}/>
          </div>
          }
          <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">...</div>
        </div>
      </div>
    </div>      

    {treeToShown && <TeamAddModal show={showTeamModal} setShow={setShowTeamModal} team={treeToShown} refresh={refresh} />}
    {treeToShown && <UserAddModal show={showUserModal} setShow={setShowUserModal} team={treeToShown} refresh={refresh} />}
    {/* {treeToShown && <UserImportModal show={showUserImportModal} setShow={setShowUserImportModal} team={treeToShown} refresh={refresh} />} */}
    {treeToShown && <CsvFileImportModal show={showUserImportModal} setShow={setShowUserImportModal}
      apiUrl={`/api/team/${treeToShown.ID}/import`} formTitle={`Import Users (.csv) to Team ${treeToShown.Name}`} 
      fileFormat={'Email,FirstName,LastName'} refresh={refresh}/>}
    <Tooltip anchorSelect="#btn-add-team" place="top">Add a new sub-team</Tooltip>
    <Tooltip anchorSelect="#btn-delete-team" place="top">Merge selected team to {mergeTeamMsg}</Tooltip>
    <Tooltip anchorSelect="#btn-add-user">Add a new team user</Tooltip>
    <Tooltip anchorSelect="#btn-import-user">Import team users from csv</Tooltip>

    {/* <ReactJson src={{idToShown, treeToShown, teamTree, account, users, unassigned, teams}} collapsed="1" /> */}
    </>
  )
}

export const TeamTab = ({team, level, editTeam, refresh, coloredID}) => {
  if (team)
  return (
    <>
    <tr class={coloredID === team.ID ? !editTeam && "table-info" : ""} onClick={()=>refresh(team.ID)}>
      <td style={{textIndent: `${(level-1)*15}px`}}>
        <FontAwesomeIcon icon={team.ParentTeamID === 0
          ? faUserTie
          : team.IsDefault 
            ? faPeopleGroup 
            : level > 2 ? faSuitcaseMedical : faBookmark} /> &nbsp; 
        {team.Name && team.Name} &nbsp; 
        {team.Users && team.Users.length
        ? <span class="badge rounded-pill bg-secondary" style={{textIndent: '0px'}}><FontAwesomeIcon icon={faUser} /> {team.Users.length}</span>
        : ""
        } &nbsp; 
        {team.Videos && team.Videos.length
        ? <span class="badge rounded-pill bg-info" style={{textIndent: '0px'}}><FontAwesomeIcon icon={faVideo} /> {team.Videos.length}</span>
        : ""
        }
      </td>
    </tr>
    {team.SubTeams && team.SubTeams.map(team => 
      <TeamTab team={team} level={level+1} editTeam={editTeam} refresh={refresh} coloredID={coloredID} />
    )}
    </>
  )
}

export const TeamCard = ({team}) => {
  return (
  <div class="card mb-3 text-white bg-danger" style={{maxWidth: "450px"}}>
    <div class="row g-0">
      <div class="col-md-3">
        <img src="http://placehold.it/300x400" class="img-fluid rounded-start" alt="..." />
      </div>
      <div class="col-md-9">
        <div class="card-body">
          <Link to={`/team/${team.ID}`}>
            <h2 class="card-title">Team {team.ParentTeamID == 0 ? "Admin" : team.Name}</h2>
          </Link>
          <p class="card-text">Has {team && team.SubTeams && team.SubTeams.length} Team</p>
          <p class="card-text">Total {team && team.Users && team.Users.length} member</p>
        </div>
      </div>
    </div>
  </div>
  )
}

export const TeamSelector = ({teams, data, setData}) => {

  const handleSelect = (e) => {
    setData({...data, ["TeamID"]: parseInt(e.target.value)})
  }

  if (teams)
  return (
    <select class="form-select" name="teamAssigned"
      value={data.TeamID || ""}
      onChange={handleSelect}>
        <option selected>Pick a team</option>
        {teams.map(item => <option value={item.ID}>{item.Name}</option>)}
    </select>
  )
}

export const UserSelector = ({users, data, setData}) => {
  
  const handleSelect = (e) => {
    let user = users.find(item => item.ID === parseInt(e.target.value))

    if (user && user.TeamID)
      setData({...data, ["ProviderID"]: parseInt(e.target.value), ["TeamID"]: user.TeamID})
    else
      setData({...data, ["ProviderID"]: parseInt(e.target.value)})
  }

  if (users)
  return (
    <select class="form-select" name="userAssigned"
      value={data.ProviderID || ""}
      onChange={handleSelect}>
        <option selected>Pick a provider</option>
        {users.map(item => <option value={item.ID}>{item.FirstName} {item.LastName}</option>)}
    </select>
  )
}

const TeamAddModal = ({show, setShow, team, refresh}) => {

  const [teamName, setTeamName] = useState("")
  const onHide = () => setShow(false)
  const handleSubmit = () => {
    axios.get(dvSrv + `/api/team/${team.ID}/addteam?name=${teamName}`, {withCredentials: true})
    .then(({data}) => toast.success(`Team ${data.Name} added successfully.`))
    .catch((error) => {
      if (error.response.data) {
        toast.error(`Error: ${error.response.data.error}`)
      }
    })
    setShow(false)
    refresh()
  }  

  return (
    <Modal show={show} onHide={onHide} centered={true}>
      <Modal.Header closeButton>
        <Modal.Title>Add a team to Team <span class="fw-bold">{team.Name && team.Name}</span></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div class="input-group mb-3">
          <input type="text" class="form-control" placeholder="Name of the team" aria-label="Sub-team's name" aria-describedby="basic-addon2" value={teamName} onChange={e=>setTeamName(e.target.value)}/>
          <span class="input-group-text" id="basic-addon2">@ {team.Name}</span>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSubmit}>Add a team</Button>
        <Button variant="secondary" onClick={onHide}>Cancel</Button>
      </Modal.Footer>
    </Modal>  
  )
}

const UserAddModal = ({show, setShow, team, refresh}) => {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  
  const onHide = () => setShow(false)
  const handleSubmit = () => {
    axios.get(dvSrv + `/api/team/${team.ID}/adduser?firstname=${firstName}&lastname=${lastName}&email=${email}`, {withCredentials: true})
    setShow(false)
    refresh()
  }

  return (
    <Modal show={show} onHide={onHide} centered={true}>
      <Modal.Header closeButton>
        <Modal.Title>Register a user to Team <span class="fw-bold">{team.Name && team.Name}</span></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div class="input-group mb-3">
          <span class="input-group-text" id="basic-user">Username </span>
          <input type="text" class="form-control" placeholder="Email address" aria-label="Sub-team's name" aria-describedby="basic-addon2" value={email} onChange={e=>setEmail(e.target.value)}/>
        </div>
        <div class="input-group mb-3">
          <span class="input-group-text" id="basic-user">First Name</span>
          <input type="text" class="form-control" placeholder="First name" aria-label="Sub-team's name" aria-describedby="basic-addon2" value={firstName} onChange={e=>setFirstName(e.target.value)}/>
        </div>
        <div class="input-group mb-3">
          <span class="input-group-text" id="basic-user">Last Name</span>
          <input type="text" class="form-control" placeholder="Last name" aria-label="Sub-team's name" aria-describedby="basic-addon2" value={lastName} onChange={e=>setLastName(e.target.value)}/>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSubmit}>Submit</Button>
        <Button variant="secondary" onClick={onHide}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  )
}

// const UserImportModal = ({show, setShow, team, refresh}) => {
//   const session = useContext(SessionContext)
//   const [selectedFileList, setSelectedFileList] = useState()

//   const onFileChange = (e) => {
//     setSelectedFileList([...e.target.files])
//   }
 
//   const onHide = () => setShow(false)
//   const handleSubmit = () => {
//     if (!selectedFileList || selectedFileList.length ===0){
//       return
//     }

//     const formData = new FormData()
//     // formData.append("device", "A00C383") // TODO
//     selectedFileList.forEach((file, i) => {
//       formData.append(`file-${i}`, file, file.name)
//     })
    
//     axios.post(dvSrv + `/api/team/${team.ID}/import`, formData, {withCredentials: true})
//     .then(({data}) => {
//       //refresh()
//     })
//     .catch(error => {
//       console.error("Error: ", error)
//       if (error.response && error.response.status === 401) {
//         session.setData(null)
//       } else { console.error("Error: ", error) }
//     })

//     setShow(false)
//     refresh()
//   }

//   return (
//     <Modal show={show} onHide={onHide} centered={true}>
//       <Modal.Header closeButton>
//         <Modal.Title>Import Users (.csv) to Team <span class="fw-bold">{team.Name && team.Name}</span></Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <h6>Expected csv file format: Email,FirstName,LastName </h6>
//         <hr />
//         <div class="input-group-lg">
//           <input class="form-control" type="file" onChange={onFileChange} 
//             id="formImportFile" accept=".csv" />
//           {/* {selectedFileList && 
//           <button class="btn btn-info" onClick={onFileChange}>Select users file</button>
//           } */}
//         </div>
//       </Modal.Body>
//       <Modal.Footer>
//         <Button variant="primary" onClick={handleSubmit}>Submit</Button>
//         <Button variant="secondary" onClick={onHide}>Cancel</Button>
//       </Modal.Footer>
//     </Modal>
//   )
// }
export const CsvFileImportModal = ({show, setShow, apiUrl, formTitle, fileFormat,refresh}) => {
  const session = useContext(SessionContext)
  const [selectedFileList, setSelectedFileList] = useState()

  const onFileChange = (e) => {
    setSelectedFileList([...e.target.files])
  }
 
  const onHide = () => setShow(false)
  const handleSubmit = () => {
    if (!selectedFileList || selectedFileList.length ===0){
      return
    }

    const formData = new FormData()
    // formData.append("device", "A00C383") // TODO
    selectedFileList.forEach((file, i) => {
      formData.append(`file-${i}`, file, file.name)
    })
    
    axios.post(dvSrv + apiUrl, formData, {withCredentials: true})
    .then(response => {
      //setShow(false)
      toast.success("csv file imported successfully!")
      refresh()
    })
    .catch(error => {
      console.error("Error: ", error)
      toast.error(error.response.data.error)
      if (error.response && error.response.status === 401) {
        session.setData(null)
      } else { console.error("Error: ", error) }
    })

    setShow(false)
    //refresh()
  }

  return (
    <Modal show={show} onHide={onHide} centered={true}>
      <Modal.Header closeButton>
        <Modal.Title>{formTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h6>Expected csv file format: {fileFormat} </h6>
        <hr />
        <div class="input-group-lg">
          <input class="form-control" type="file" onChange={onFileChange} 
            id="formImportFile" accept=".csv" />
          {/* {selectedFileList && 
          <button class="btn btn-info" onClick={onFileChange}>Select users file</button>
          } */}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSubmit}>Submit</Button>
        <Button variant="secondary" onClick={onHide}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  )
}
