import { SidebarLogo, SidebarLogoWrapper, SidebarToggler, SidebarBrand, SidebarWrapper, SidebarContainer, ItemContainer, ItemWrapper, ItemName, ItemsList, SidebarContainerHoverable } from "./SidebarStyle";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Routes } from "../Routes";
import { SessionContext } from "../App";

// https://medium.com/@jealousgx
export default function Sidebar({displaysidebar, handleSidebarDisplay}) {

  const {data} = useContext(SessionContext)
  const routes = Routes(data)

  const [activeId, setActiveId] = useState(0)

  return (
    <SidebarContainer displaysidebar={displaysidebar}>
      <SidebarWrapper>
        
        <ItemsList>
          <ItemContainer onClick={handleSidebarDisplay}>
            <ItemWrapper icon={displaysidebar ? '\f137' : '\f138'}>
              <ItemName displaysidebar={displaysidebar}>
                Hide
              </ItemName>
            </ItemWrapper>
          </ItemContainer>
        
          {routes.map((route, index) => (
          <ItemContainer key={index} 
            onClick={() => setActiveId(route.id)}
            className={route.id === activeId ? "active" : ""}>
              <Link to={route.path}>
                <ItemWrapper icon={route.ico}>
                  {/* <label></label> */}
                  {/* <i class="icon-home">{itemData.icon}</i> */}
                  {/* <FontAwesomeIcon icon={faHouse} /> */}
                  {/* <FontAwesomeIcon icon={['fas', 'house']} /> */}
                  {/* <FontAwesomeIcon icon={`${itemData.icon}`} /> */}

                  <ItemName displaysidebar={displaysidebar}>
                    {route.name}
                  </ItemName>
                </ItemWrapper>
              </Link>
            </ItemContainer>
          ))}

        </ItemsList>
        {/* <SidebarItems displaysidebar={displaysidebar} /> */}
      </SidebarWrapper>
    </SidebarContainer>
  )
}

// const Sidebar2 = () => {
//   const [sidebar, setSidebar] = useState(false);

//   const showSidebar = () => setSidebar(!sidebar);

//   return (
//     <>
//       <IconContext.Provider value={{ color: '#fff' }}>
//         <Nav>
//           <NavIcon to='#'>
//             <FaIcons.FaBars onClick={showSidebar} />
//           </NavIcon>
//         </Nav>
//         <SidebarNav sidebar={sidebar}>
//           <SidebarWrap>
//             <NavIcon to='#'>
//               <AiIcons.AiOutlineClose onClick={showSidebar} />
//             </NavIcon>
//             {SidebarData.map((item, index) => {
//               return <SubMenu item={item} key={index} />;
//             })}
//           </SidebarWrap>
//         </SidebarNav>
//       </IconContext.Provider>
//     </>
//   );
// };
 
// const SidebarLink = styled(Link)`
//   display: flex;
//   color: #e1e9fc;
//   justify-content: space-between;
//   align-items: center;
//   padding: 20px;
//   list-style: none;
//   height: 40px;
//   text-decoration: none;
//   font-size: 18px;

//   //#c6c8cf
//   //#252831
//   &:hover {
//     background: #c6c8cf;
//     border-left: 4px solid #632ce4;
//     cursor: pointer;
//   }
// `;

// const SidebarLabel = styled.span`
//   margin-left: 16px;
// `;

// const DropdownLink = styled(Link)`
//   background: #414757;
//   height: 40px;
//   padding-left: 3rem;
//   display: flex;
//   align-items: center;
//   text-decoration: none;
//   color: #f5f5f5;
//   font-size: 18px;

// //  background: #632ce4;

//   &:hover {
//     background: #d9dbd5;
//     cursor: pointer;
//   }
// `;

// const SubMenu = ({ item }) => {
//   const [subnav, setSubnav] = useState(false);

//   const showSubnav = () => setSubnav(!subnav);

//   return (
//     <>
//       <SidebarLink to={item.path} onClick={item.subNav && showSubnav}>
//         <div>
//           {item.icon}
//           <SidebarLabel>{item.title}</SidebarLabel>
//         </div>
//         <div>
//           {item.subNav && subnav
//             ? item.iconOpened
//             : item.subNav
//             ? item.iconClosed
//             : null}
//         </div>
//       </SidebarLink>
//       {subnav &&
//         item.subNav.map((item, index) => {
//           return (
//             <DropdownLink to={item.path} key={index}>
//               {item.icon}
//               <SidebarLabel>{item.title}</SidebarLabel>
//             </DropdownLink>
//           );
//         })}
//     </>
//   );
// };

// export const SidebarData = [
//   {
//     title: 'Login',
//     path: '/login',
//     icon: <faCogs />,
//   },
//   {
//     title: 'Site Master',
//     path: '/list-sitemaster',
//     icon: <faCogs />,
//   },
//   {
//     title: 'Site Report Cards',
//     path: '/sitereportcardmaster',
//     icon: <faCogs />,
   
//   },
//   {
//     title: 'Report Card Master',
//     path: '/reportcardmaster',
//     icon: <faCogs />
//   },
//   {
//     title: 'Categories',
//     path: '/categories',
//     icon: <faCogs />,

//     iconClosed: <faCogs />,
//     iconOpened: <faCogs />,

//     subNav: [
//       {
//         title: 'Asset Type',
//         path: '/assettype',
//         icon: <faCogs />,
//         cName: 'sub-nav'
//       },
//       {
//         title: 'Asset Sub Type',
//         path: '/assetsubtype',
//         icon: <faCogs />,
//         cName: 'sub-nav'
//       },
//       {
//         title: 'Document Type',
//         path: '/documenttype',
//         icon: <faCogs />,
//         cName: 'sub-nav'
//       },
//       {
//         title: 'Document Sub Type',
//         path: '/documentsubtype',
//         icon: <faCogs />,
//         cName: 'sub-nav'
//       },
//       {
//         title: 'Report Card Category',
//         path: '/reportcarddetailcategory',
//         icon: <faCogs />,
//         cName: 'sub-nav'
//       },
//       {
//         title: 'Contact Detail Type',
//         path: '/contactdetailtype',
//         icon: <faCogs />,
//         cName: 'sub-nav'
//       },
//       {
//         title: 'Lighting Type',
//         path: '/lightingtype',
//         icon: <faCogs />,
//         cName: 'sub-nav'
//       }
//     ]
//   },
//   {
//     title: 'Company Master',
//     path: '/cpymaster-toplevel',
//     icon: <faCogs />,
//     iconClosed: <faCogs />,
//     iconOpened: <faCogs />,

//     subNav: [
//       {
//         title: 'CompanyMaster',
//         path: '/cpymaster',
//         icon: <faCogs />,
//         cName: 'sub-nav'
//       },
//       {
//         title: 'Users',
//         path: '/users',
//         icon: <faCogs />,
//         cName: 'sub-nav'
//       },
//       {
//         title: 'Contacts',
//         path: '/contactmaster',
//         icon: <faCogs />,
//         cName: 'sub-nav'
//       }
//     ]
//   },
//   {
//     title: 'My Profile',
//     path: '/add-usersmall',
//     icon: <faCogs/>,
//   },
//   {
//     title: 'PDF',
//     path: '/pdfcomponent',
//     icon: <faCogs />,
//   },
//   {
//     title: 'Users PageSort Test',
//     path: '/userstable',
//     icon: <faCogs />,
//   },
//   {
//     title: 'Logout',
//     path: '/logout',
//     icon: <faCogs />,
//   }
// ];