export default function Footer() {
  return (
    <footer>
      <div class="copyright">
        &copy; Deepvin, Inc. All rights reserved.<br />
        <a href="/disclaimer">Disclaimer</a>&nbsp;&nbsp;
        <a href="/privacy">Privacy Statement</a>    
      </div>
    </footer>
  )
}