import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { dvSrv, SessionContext } from '../App';
import { GetNodeVideos } from './Team';
import { TimeStringForTimeZone } from './Setting';
import ReactJson from "@microlink/react-json-view";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowAltCircleUp, faBackward, faBackwardFast, faCircleStop, faForward, faForwardFast, faSearch, faSearchMinus, faSearchPlus, faSort, faSortAsc, faSortDesc, faStopCircle, faUndo, faUndoAlt, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { GetDeviceSNWithTag } from './Device';
import { GetUserFullname } from './User';
import { GetAccountName } from './Account';

// NOTE:
// If "Search by ..." is being used, the pagination is disabled for now, and the query to apiUrl will return all items to client,
// and the "Search" will operate within frontend JS code. The pagination will be activate again when "Search" is closed.

export const PaginationTable = ({ apiUrl, columns, refresh, repaging, ItemComponent, itemProps = {} }) => {
  const [data, setData] = useState({})
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [sortBy, setSortBy] = useState('created_at')
  const [order, setOrder] = useState('desc')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [isSearching, setIsSearching] = useState(false)
  const [searchData, setSearchData] = useState([])
  const [searchResults, setSearchResults] = useState([])
  const [searchBoxIDs, setSearchBoxIDs] = useState({})

  const [hoveredColumn, setHoveredColumn] = useState(null)
  const handleMouseOver = (column) => {
    setHoveredColumn(column);
  }
  const handleMouseOut = () => {
    setHoveredColumn(null);
  }

  const limits =[10, 15, 20, 25, 30]
  const handleLimitSelect = (e) => {
    setLimit(parseInt(e.target.value)) 
  }

  const [flag, setFlag] = useState(false)
  const selfrefresh = () => {
    setFlag(!flag)
    refresh()
  }

  useEffect(() => {

    if (!apiUrl || apiUrl === "")
        return
    console.log("###### apiUrl: ", apiUrl)
    axios.get(dvSrv + apiUrl, 
      isSearching ? // when "Search" is used, all query items will be returned without Pagination
      {withCredentials: true} :
      {params: {
          page,
          limit,
          sortBy,
          order,
          startDate,
          endDate,
        }, withCredentials: true})
    .then(({data}) => {
        if(isSearching){
          // If this is Devices or RawFiles array, just return it; 
          // else this is the TeamDataTree, so we need to retrieve all videos

          let sData = Array.isArray(data) ? data : GetNodeVideos(data)
          setSearchData(sData)
          setSearchResults(sData)
          setData({})
        } else {
          setData(data) // when "Search" is used, all query items will be returned without Pagination
          if (data && data.PageItems){
            setPage(data.CurrentPage > data.TotalPage ? data.TotalPage : data.CurrentPage)
          }
          setSearchData([])
          setSearchResults([])
        }
        setSearchBoxIDs({})
    })
    .catch(error => { 
        console.error("Error: ", error) 
        //toast.error(error.response.data.error)
    })
  }, [flag, apiUrl, page, limit, sortBy, order, startDate, endDate, repaging, isSearching])

  const handleSort = (column) => {
    const sortOrder = sortBy === column && order === 'asc' ? 'desc' : 'asc'
    setSortBy(column)
    console.log("### Sort by: ", column)
    setOrder(sortOrder)
  }

  const handlePageChange = (newPage) => {
    setPage(newPage);
  }

  const handleDateChange = (setter) => (event) => {
    setter(event.target.value)
  }

  const toggleSearch = (e) => {
    setSearchBoxIDs({})
    setIsSearching(!isSearching) // Toggle the state
  }

  const resetSearch = (e) => {   
    Object.keys(searchBoxIDs).forEach(id => {
      const inputElement = document.getElementById(id)
      console.log(`inputElement: ${inputElement}, id: ${id}`)
      if (inputElement) {
          inputElement.value = '' // Clear the input value directly
      }
    })

    setSearchBoxIDs({})
    setFlag(!flag) // re-render
  }

  const session = useContext(SessionContext)
  //const hasTeams = itemProps?.teams !== undefined;
  const hasUsers = itemProps?.users !== undefined;
  const hasDevices = itemProps?.devices !== undefined;
  const hasAccts = itemProps?.accounts !== undefined;
  const getSpecialFieldVal =(item, f) => {
    if (f === "CreatedAt" || f==="CreateDate" 
      || f === "InvoiceDate" || f === "DueDate" || f === "StartDate" // for Invoices
    ) // Time format is mm/dd/yyyy hh:min am/pm
     return TimeStringForTimeZone(session,item[f])
    else if(f === "Source" && hasDevices) {// The text format "<Source> | <Tag>"
      return  GetDeviceSNWithTag (item.Source, itemProps.devices)
    }
    else if(f === "ProviderID" && hasUsers) {// The text format for the user "<FirstName> <LastName>"
      return GetUserFullname (item.ProviderID, itemProps.users)
    }
    else if((f === "CustomerID" || f === "DistributorID") && hasAccts) {
      return f === "CustomerID" 
              ? GetAccountName (item.CustomerID, itemProps.accounts)
              : GetAccountName (item.DistributorID, itemProps.accounts)
    }
    else
      return item[f]
  }

  const getDeviceTag =(item, f) => {

  }
  const getProviderFullname =(item, f) => {}

  const isItemFound = (item, field, val) =>{

    for (const [f, v] of Object.entries(searchBoxIDs)){
      console.log(`Key: ${f}, Value: ${v}, itemFieldVal: ${item[f]}`)
      let value = f === field ? val : v
      if (getSpecialFieldVal(item, f).toLowerCase().includes(value.toLowerCase()) !== true)
        return false
    }

    return Object.keys(searchBoxIDs).includes(field) ? true : 
      getSpecialFieldVal(item, field).toLowerCase().includes(val.toLowerCase())
  }

  const handleSearchChange = (field, val) =>{
    console.log(`ChangingKey: ${field}, Value: ${val}`)
    
    setSearchResults(searchData.filter(item => isItemFound(item, field, val)))
    setSearchBoxIDs({...searchBoxIDs, [field]:val});
  }

  if (data || searchData)
  return (
    <>
     <div class="row mx-1">
      <button id="searchButton" class="btn btn-sm btn-outline-primary mt-2 col-2" onClick={toggleSearch}>
      <FontAwesomeIcon icon={isSearching ? faWindowClose : faSearch } /><span class="fw-bold"> {isSearching ? "Close Search" : "Search by ..."}</span></button>
      {isSearching && <button id="resetSearchButton" class="btn btn-sm btn-outline-primary mt-2 col-2 mx-1" onClick={resetSearch}>
          <FontAwesomeIcon icon={faUndo } /><span class="fw-bold"> Reset Search</span></button>}
      </div>
      
      {isSearching ?
        <div class="row mt-2 mb-2">
          
          {columns.map((column) => column.searchable &&           <div class="form-floating col-2">
            <input data-cy={column.key} type="text" class="form-control" id={column.jfield} placeholder={column.label} 
            onChange={(e) => handleSearchChange(column.jfield, e.target.value)}/>
            <label for={column.jfield} class="mx-2">{column.label}</label>
          </div>
          )}
        </div>
        :
        data?.PageItems?.length > 0 && <div class="my-3">
        <label class="fw-bold">
          Added From:&nbsp;
          <input class="me-3" type="date" value={startDate} onChange={handleDateChange(setStartDate)} />
        </label>
        <label  class="fw-bold">
          To:&nbsp;
          <input class="me-3" type="date" value={endDate} onChange={handleDateChange(setEndDate)} />
        </label>
        </div> 
      }

      <table className="table table-hover">
        <thead>
          <tr>
            {columns.map((column) => column.sortable && !isSearching
                ? <th key={column.key} style={{ backgroundColor: sortBy===column.key?'lightgrey':'' }}
                    onMouseOver={() => handleMouseOver(column.key)}
                    onMouseOut={handleMouseOut}
                    onClick={() => handleSort(column.key)}>
                    {column.label} {sortBy===column.key ? <FontAwesomeIcon icon={order==='asc'? faSortAsc : faSortDesc}/>
                    : hoveredColumn === column.key && <FontAwesomeIcon icon={faSort} />}</th> 
                : <th class="text-center" key={column.key}>{column.label}</th>
            )}
          </tr>
        </thead>
        <tbody>
          {isSearching 
          ? searchResults?.map((item) => (<ItemComponent key={item.ID} item={item} refresh={selfrefresh} {...itemProps} />)) 
          : data?.PageItems?.map((item) => (<ItemComponent key={item.ID} item={item} refresh={selfrefresh} {...itemProps} />)) 
          }
        </tbody>
      </table>
      { !isSearching && data?.PageItems?.length > 0 &&
        <div class="row">
            <div class="col-2 col-sm-2">
            <label class="fw-bold col-form-labe me-0">Rows per Page:</label>
            </div>
            <div class="col-1 col-sm-2 me-5">
                <select class="form-select form-select-sm"
                    value={limit}
                    onChange={handleLimitSelect}>
                    {limits.map((val) => <option value={val}>{val}</option>)}
                </select>                  
            </div>

            <div class="col-2 col-sm-3">
                <button class="btn btn-outline-secondary btn-sm me-1" onClick={() => handlePageChange(1)} disabled={data.TotalPage === 0 || page <= 1}><FontAwesomeIcon icon={faBackwardFast} /></button>
                <button class="btn btn-outline-secondary btn-sm me-3" onClick={() => handlePageChange(page - 1)} disabled={data.TotalPage === 0 || page <= 1}><FontAwesomeIcon icon={faBackward} /></button>
                <span class="badge rounded-pill bg-info me-3">{(data.CurrentPage-1)*data.PageLimit+1}~{(data.CurrentPage-1)*data.PageLimit+data.PageItems.length} / {data.TotalItem}</span>
                <button class="btn btn-outline-secondary btn-sm me-1" onClick={() => handlePageChange(page + 1)} disabled={data.TotalPage === 0 || page >= data.TotalPage}><FontAwesomeIcon icon={faForward} /></button>
                <button class="btn btn-outline-secondary btn-sm me-2" onClick={() => handlePageChange(data.TotalPage)} disabled={data.TotalPage === 0 || page >= data.TotalPage}><FontAwesomeIcon icon={faForwardFast} /></button>
            </div>
        </div>
      }
      {/* <ReactJson src={searchBoxIDs} /> */}
      {/* <ReactJson src={searchData} /> */}
    </>
  )
}
