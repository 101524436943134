import axios from "axios"
import moment from "moment"
import { useContext, useEffect, useState } from "react"
import { NumericFormat } from "react-number-format"
import { Link } from "react-router-dom"
import { dvSrv, SessionContext } from "../App"
import { toast } from "react-toastify"
import { Button, Modal } from "react-bootstrap"
import { DateTimePicker } from "react-datetime-picker"
import { PaginationTable } from "./Pagination"
import ReactJson from "@microlink/react-json-view"

import { Worker, Viewer, WorkerMessageHandler } from '@react-pdf-viewer/core'; // Core viewer
import '@react-pdf-viewer/core/lib/styles/index.css'; // Import necessary styles

// Default layout plugin (optional, adds toolbar, zooming, etc.)
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css'; // Import necessary styles

// Import the worker from pdfjs-dist
import 'pdfjs-dist/build/pdf.worker.min.js';

export const Invoices = () => {
  const session = useContext(SessionContext)
  const [accounts, setAccounts] = useState([]) 
  const [invoiceToEdit, setInvoiceToEdit] = useState(null)
  const [invoiceToView, setInvoiceToView] = useState(null)
  const [emailToSend, setEmailToSend] = useState(null)
  
  const [flag, setFlag] = useState(false)
  const [showNew, setShowNew] = useState(false)
  const [showView, setShowView] = useState(false)

  // const accountSelected = (account) => {
  //   setFlag(!flag)
  //   if (account) setAccountToShown(account)
  // }

  const invoiceUrl = '/api/invoice/'

  useEffect(() => {
    axios.get(dvSrv + "/api/account/", {withCredentials: true})
    .then(({data}) => {
      setAccounts(data.filter(a=>a.AccountType === "Customer" || a.AccountType === "Distributor"))
    })
    .catch(error => {
      if (error.response.status === 401) {
        session.setData(null)
      } else { console.error("Error: ", error) }
    })
  }, [])

  const handleNewInvoice = (e) => {
    setShowView(false)
    setShowNew(!showNew)
    setInvoiceToEdit(null)
    setInvoiceToView(null)
  }

  const handleEditInvoice = (invoice) =>{
    setShowNew(true)
    setShowView(false)
    setInvoiceToEdit(invoice)
  }

  const handleViewInvoice = (invoice, email) =>{
    console.log("Handle Invoice view ...")
    setShowNew(false)
    setEmailToSend(email)
    setShowView(true)
    setInvoiceToEdit(null)
    setInvoiceToView(invoice)
  }
  
  const handleUpdate = () => {
    setShowNew(false)
    setShowView(false)
    setInvoiceToEdit(null)
    setInvoiceToView(null)
    setFlag(!flag)
  }

  return (
    <>
      <div>
      <h1>Deepvin SuperAdmin Page !!!</h1>
      <h2>All Invoices</h2>
      <div>
        <InvoiceTableWithPagination apiUrl={invoiceUrl} accounts={accounts} refresh={handleUpdate} repaging={flag} 
          editCallback={handleEditInvoice} viewCallback={handleViewInvoice}/>
      </div>
      <button class="btn btn-outline-secondary me-3" disabled={accounts.length === 0} onClick={handleNewInvoice}>Create Invoice</button>
    </div>
    {showNew && <NewInvoiceModal show={showNew} setShow={setShowNew} refresh={handleUpdate}
                    accounts={accounts} invoice={invoiceToEdit}/>}
    {showView && <ViewInvoiceModal show={showView} setShow={setShowView}
                    invoice={invoiceToView} email={emailToSend} refresh={handleUpdate}/>}
    </>
  )
}

/*
type Invoice struct {
	gorm.Model
	Name                string // yes input
	CreatedBy           uint
	ModifiedBy          uint
	CustomerID          uint // yes input
	DistributorID       uint // yes input
	QuoteID             uint
	QuoteDate           time.Time
	InvoiceDate         time.Time // yes input
	DueDate             time.Time // yes input
	StartDate           time.Time // yes input
	EndDate             time.Time
	ProductQty          uint    // yes input
	ProductCallNumber   uint    // null for unlimited  // yes input
	ProductTermDuration uint    // by year // yes input
	CalculatedUnitPrice float64 // calculated, averaged annually // yes, calculated for display
	SubtotalAmount      float64 // yes input
	TaxRate             float64 // yes input
	TaxAmount           float64 // yes, calculated for display
	TotalAmount         float64 // yes, calculated for display
	StripePaymentID     *string
	Emailed             *string // view button -> pdf -> email button
	Note                string  // yes input
	Locked              bool    // yes input
	Completed           bool    // yes input, include email sent
	Address                     // billing address
}
*/
const _columns = [
  { key: 'id', label: 'ID', jfield:'ID', sortable: true, searchable: false },
  { key: 'name', label: 'Invoice Name', jfield:'Name', sortable: true, searchable: true },
  { key: 'customer_id', label: 'Customer', jfield:'CustomerID', sortable: true, searchable: true  },
  { key: 'distributor_id', label: 'Distributor', jfield:'DistributorID', sortable: true, searchable: true },
  { key: 'invoice_date', label: 'Invoice Date', jfield:'InvoiceDate', sortable: true, searchable: true },
  { key: 'due_date', label: 'Due Date', jfield:'DueDate', sortable: true, searchable: true },
  { key: 'start_date', label: 'Start Date', jfield:'StartDate', sortable: true, searchable: true },
  { key: 'product_qty', label: 'Device Qty', jfield:'ProductQty', sortable: true, searchable: false },
  { key: 'product_call_number', label: 'Call Volume', jfield:'ProductCallNumber', sortable: true, searchable: false },
  { key: 'product_term_duration', label: 'Term (years)', jfield:'ProductTermDuration', sortable: true, searchable: false },
  
  { key: 'subtotal_amount', label: 'Subtotal', jfield:'SubtotalAmount', sortable: true, searchable: false },
  { key: 'tax_rate', label: 'Tax Rate(%)', jfield:'TaxRate', sortable: true, searchable: false },
  { key: 'tax_amount', label: 'Tax Amount', jfield:'TaxAmount', sortable: true, searchable: false },
  { key: 'total_amount', label: 'Total', jfield:'TotalAmount', sortable: true, searchable: false },
  { key: 'calculated_unit_price', label: 'Unit Price', jfield:'CalculatedUnitPrice', sortable: true, searchable: false },

  { key: 'note', label: 'Note', jfield:'Note', sortable: false, searchable: false }, 
  { key: 'locked', label: 'Locked', jfield:'Locked', sortable: false, searchable: false },
  { key: 'completed', label: 'Completed', jfield:'Completed', sortable: false, searchable: false },
  { key: 'action', label: 'Action', jfield:'NA', sortable: false, searchable: false },
  // Add more columns as needed
]

export const InvoiceTableWithPagination = ({apiUrl, accounts, refresh, repaging, editCallback, viewCallback}) => {

  const itemProps = {
    accounts: accounts,
    editCallback: editCallback,
    viewCallback: viewCallback,
  }
  return (
    <PaginationTable apiUrl={apiUrl} columns={_columns} refresh={refresh} repaging={repaging} ItemComponent={InvoiceTab} itemProps={itemProps}/>
  )
}

export const InvoiceTab = ({item, editCallback, viewCallback, accounts}) => {
  const [data, setData] = useState(item)
  const [edit, setEdit] = useState(false)

  const getAccount = (aid) => {
    const acct = aid && accounts && accounts.find((a) => a.ID === aid)
    return acct
  }
  // const getDistributorName = (did) => {
  //   switch (did) {
  //     case "a0bf67e6-c3d9-a28c-00de-5d3608615302":
  //       return "UE Medical"
  //     case "a528ee7b-c0ce-4118-7be0-5c8a83460c2e":
  //       return "Bound Tree Medical"
  //   }
  // }
  const customer = getAccount(item.CustomerID)
  const distributor = getAccount(item.DistributorID)

  const handleEdit = (e) => {
    setEdit(!edit)
    editCallback(item)
  }
  const handleEmail = (e) =>{
    viewCallback(item, customer.InvoiceEmail)
  }

  if (item)
  return (
    <tr>
      <td>{item.ID}</td>
      <td>{item.Name}</td>
      <td>{customer && <Link to={`/account/${customer.UUID}/team`}>{customer.Name}</Link>}</td>
      <td>{distributor && <Link to={`/account/${distributor.UUID}/team`}>{distributor.Name}</Link>}</td>
      <td>{moment(item.InvoiceDate).format("YYYY-MM-DD")}</td>
      <td>{moment(item.DueDate).format("YYYY-MM-DD")}</td>
      <td>{moment(item.StartDate).format("YYYY-MM-DD")}</td>
      <td>{item.ProductQty}</td>
      <td>{item.ProductCallNumber}</td>
      <td>{item.ProductTermDuration}</td>
      
      <td class="text-end"><NumericFormat value={item.SubtotalAmount} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      <td>{item.TaxRate}</td>
      <td class="text-end"><NumericFormat value={item.TaxAmount} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      <td class="text-end"><NumericFormat value={item.TotalAmount} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      <td class="text-end"><NumericFormat value={item.CalculatedUnitPrice} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      
      <td>{item.Note}</td>
      <td class="text-center">
        <input readOnly class="form-check-input mt-0" type="checkbox" style={{ border: '1px solid darkgrey' }} checked={item.Locked}  
        /* onChange={(e) => handleValueChange("Locked", e.target.checked)} disabled={!edit}*/ />
      </td>
      <td class="text-center">
        <input readOnly class="form-check-input mt-0" type="checkbox" style={{ border: '1px solid darkgrey' }} checked={item.Completed}  
        /* onChange={(e) => handleValueChange("Completed", e.target.checked)} disabled={!edit} */ />
      </td>

     <td>
      {item.Emailed || <button type="button" class="btn btn-info p-0 fw-bold btn-sm btn-block" onClick={handleEmail}>Email</button>}&nbsp;&nbsp;
      {item.Completed || <button type="button" class="btn btn-danger p-0 fw-bold btn-sm btn-block" onClick={handleEdit}>Edit</button>}
      </td>
    </tr>
    
  )
}

const NewInvoiceModal = ({invoice, show, setShow, refresh, accounts}) => {

  const [data, setData] = useState(invoice ? invoice : {})
  const [edit, setEdit] = useState(invoice ? true : false)
  const [recalculate, setRecalculate] = useState(invoice ? false : true)

  //const [invoiceDate, setInvoiceDate] = useState(invoice ? invoice.InvoiceDate : '')
  //const [dueDate, setDueDate] = useState(invoice ? invoice.DueDate : '')
  //const [startDate, setStartDate] = useState(invoice ? invoice.StartDate : '')
  const setInvoiceDate = (date) =>{handleValueChange("InvoiceDate", date)}
  const setDueDate = (date) =>{handleValueChange("DueDate", date)}
  const setStartDate = (date) =>{handleValueChange("StartDate", date)}
  
  const convertFloatFields = () =>{
    const fields = Object.keys(data)
    if (fields.includes("SubtotalAmount")){
      console.log("contains SubtotalAmount: ", parseFloat(data["SubtotalAmount"]))
      data["SubtotalAmount"] = parseFloat(data["SubtotalAmount"])
      //setData({...data, ["SubtotalAmount"]: parseFloat(data["SubtotalAmount"])})
    }
    if (fields.includes("TaxRate")){
      console.log("contains taxrate: ", parseFloat(data["TaxRate"]))
      //setData({...data, ["TaxRate"]: parseFloat(data["TaxRate"])})
      data["TaxRate"] = parseFloat(data["TaxRate"])
    }

  }

  const calculatePrice = (e) =>{
    const fields = Object.keys(data)
    let subtot = parseFloat(data["SubtotalAmount"])
    subtot = !isNaN(subtot) ? subtot : 0
    let taxrate = parseFloat(data["TaxRate"])
    taxrate = !isNaN(taxrate) ? taxrate : 0
    
    let taxamnt = parseFloat(subtot * taxrate / 100.0).toFixed(2)
    let tot = parseFloat(subtot) + parseFloat(taxamnt)
    
    let years = parseInt(data["ProductTermDuration"])
    let numDevices = parseInt(data["ProductQty"])
    let unitprice = (years === 0 || numDevices === 0) ? 0 : parseFloat(subtot / (years * 12 * numDevices)).toFixed(2)

    console.log("subtot, taxrate, taxamount, tot: ", subtot, taxrate, taxamnt, tot)
    console.log("years, numDevices, unitprice: ", years, numDevices, unitprice)

    // setData({
    //   ...data,
    //   ["TaxAmount"]: parseFloat(taxamnt),
    //   ["TotalAmount"]: parseFloat(tot),
    //   ["CalculatedUnitPrice"]: parseFloat(unitprice)
    // })
    setData(prevData => ({
      ...prevData,  // This copies all the previous fields from data
      TaxAmount: parseFloat(taxamnt),
      TotalAmount: parseFloat(tot),
      CalculatedUnitPrice: parseFloat(unitprice)
    }))
    setRecalculate(false)
  }
  
  //const regex = /^[+-]?\d*\.?\d*$/;
  const regex = new RegExp("^[+-]?\\d*\\.?\\d*$")

  const handleValueChange = (field, value) => {
    switch (field) {
      case "DistributorID":
      case "CustomerID":
      case "ProductQty":
      case "ProductCallNumber":
      case "ProductTermDuration":
        //console.log("selected val: ", value)
        setData({...data, [field]: parseInt(value)})
        
        if (field === "ProductQty" || field === "ProductTermDuration"){
          setRecalculate(true)
          //calculatePrice()
        }
        break
      case "SubtotalAmount":
      case "TaxRate":
      //case "TaxAmount":
      //case "TotalAmount":
        if (value === '' || regex.test(value)){
          //let val = parseFloat(value)
          //console.log("parseFloat = ", val)
          setData({...data, [field]: value})
          setRecalculate(true)
          //calculatePrice()
        }
        break
      default:
        setData({...data, [field]: value})
    }
  }

  const onHide = () => setShow(false)

  const reset = () =>{
    setShow(false)
    setData(edit ? invoice : {})
    setEdit(false)
  }

  const handleDateChange = (setter) => (date) => {
    console.log("time event: ", date)
    setter(date)
  }

  const handleCancel = (e) => {
      reset()
  }

  const createInvoice = () => {
    convertFloatFields()
    axios.post(dvSrv + `/api/invoice/new`, 
      data, 
      {withCredentials: true})
    .then(({data}) => {
          refresh()
      }).catch(error => console.error("Error creating new account: ", error))
  }
  
  const updateInvoice = () => {
    convertFloatFields()
    axios.post(dvSrv + `/api/invoice/${invoice.ID}`, data, {withCredentials: true})
    .then(({data}) => {
      refresh()
    })
    .catch(error => { 
      console.error("Error: ", error) 
      toast.error(error.response.data.error)
    } )
  }  

  const handleSubmit = () => {
    if (edit){
      updateInvoice()
    } else {
      createInvoice()
    }
    reset()
  }

  const acct = invoice ? accounts.find(a => a.ID === invoice.CustomerID) : null
  const title = acct ? "Update Invoice for " + acct.Name : "Create New Invoice"

  return (
    <Modal show={show} onHide={onHide} centered={true}
      backdrop="static" // This disables closing by clicking the backdrop.
      keyboard={false}  // This disables closing by pressing the Escape key.
    >

      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div class="input-group mb-1 row">
          <span class="input-group-text col-sm-4" id="inv-id">ID </span>
          <input type="text" readOnly class="col-sm-8" placeholder="Invoice ID" aria-label="Invoice setup" aria-describedby="basic-addon2" 
            value={data?.ID} />
        </div>
        <div class="input-group mb-1 row">
          <span class="input-group-text col-sm-4" id="inv-name">Invoice Name </span>
          <input type="text" class="form-control col-sm-8" placeholder="Invoice Name" aria-label="Invoice setup" aria-describedby="basic-addon2" 
            value={data?.Name} onChange={e=>handleValueChange("Name", e.target.value)}/>
        </div>
        <div class="input-group mb-1 row">
          <span class="input-group-text col-sm-4" id="inv-customer">Customer </span>
          { invoice ? <input type="text" readOnly class="col-sm-8" placeholder="Customer" aria-label="Invoice setup" aria-describedby="basic-addon2" 
            value={acct?.Name} /*onChange={e=>handleValueChange("CustomerID", e.target.value)}*/ />
            : <AccountSelector id={data.CustomerID} accounts={accounts.filter(a=>a.AccountType === "Customer")} 
            handleChange={e=>handleValueChange("CustomerID", e.target.value)}/>}
        </div>
        <div class="input-group mb-1 row">
          <span class="input-group-text col-sm-4" id="inv-distributor">Distributor </span>
          <AccountSelector id={data.DistributorID} accounts={accounts.filter(a=>a.AccountType === "Distributor")}
          handleChange={e=>handleValueChange("DistributorID", e.target.value)}/>
        </div>
        <div class="input-group mb-1 row">
          <span class="input-group-text col-sm-4" id="inv-invoicedate">Invoice Date </span>
          <div class="col-sm-8">
            <DateTimePicker disableClock={true} format="y-MM-dd"
              onChange={handleDateChange(setInvoiceDate)} value={data?.InvoiceDate}/>
          </div>
        </div>        
        <div class="input-group mb-1 row">
          <span class="input-group-text col-sm-4" id="inv-duedate">Due Date </span>
          <div class="col-sm-8">
          <DateTimePicker disableClock={true} format="y-MM-dd"
              onChange={handleDateChange(setDueDate)} value={data?.DueDate}/>
          </div>
        </div>        
        <div class="input-group mb-1 row">
          <span class="input-group-text col-sm-4" id="inv-startdate">Start Date </span>
          <div class="col-sm-8">
          <DateTimePicker disableClock={true} format="y-MM-dd"
              onChange={handleDateChange(setStartDate)} value={data?.StartDate}/>
          </div>
        </div>        
        <div class="input-group mb-1 row">
          <span class="input-group-text col-sm-4" id="inv-quantity">Quantity </span>
          <input type="number" class="form-control col-sm-8" placeholder="Quantity" aria-label="Invoice setup" aria-describedby="basic-addon2" 
            value={data?.ProductQty} onChange={e=>handleValueChange("ProductQty", e.target.value)}/>
        </div>        
        <div class="input-group mb-1 row">
          <span class="input-group-text col-sm-4" id="inv-callvolume">Call Volume </span>
          <input type="number" class="form-control col-sm-8" placeholder="Call Volume" aria-label="Invoice setup" aria-describedby="basic-addon2" 
            value={data?.ProductCallNumber} onChange={e=>handleValueChange("ProductCallNumber", e.target.value)}/>
        </div> 
        <div class="input-group mb-1 row">
          <span class="input-group-text col-sm-4" id="inv-termduration">Term (years) </span>
          <input type="number" class="form-control col-sm-8" placeholder="Term (years)" aria-label="Invoice setup" aria-describedby="basic-addon2" 
            value={data?.ProductTermDuration} onChange={e=>handleValueChange("ProductTermDuration", e.target.value)}/>
        </div>
        <div class="input-group mb-1 row">
          <span class="input-group-text col-sm-4" id="inv-subtotal">Subtotal Amount </span>
          {/* <NumericFormat value={data?.SubtotalAmount} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={'$'} /> */}
          <input type="text" class="form-control col-sm-8" placeholder="Subtotal Amount" aria-label="Invoice setup" aria-describedby="basic-addon2" 
            value={data?.SubtotalAmount} onChange={e=>handleValueChange("SubtotalAmount", e.target.value)}/>
        </div>
        <div class="input-group mb-1 row">
          <span class="input-group-text col-sm-4" id="inv-taxrate">Tax Rate(%) </span>
          <input type="text" class="form-control col-sm-8" placeholder="Tax Rate(%)" aria-label="Invoice setup" aria-describedby="basic-addon2" 
            value={data?.TaxRate} onChange={e=>handleValueChange("TaxRate", e.target.value)}/>
        </div>

        <div class="input-group mb-1 row">
          <span class="input-group-text col-sm-4" id="inv-taxamount">Tax Amount </span>
          <input type="text" readOnly class="col-sm-8" placeholder="Tax Amount" aria-label="Invoice setup" aria-describedby="basic-addon2" 
            value={data?.TaxAmount} />
        </div>        
        <div class="input-group mb-1 row">
          <span class="input-group-text col-sm-4" id="inv-totalamount">Total Amount </span>
          <input type="text" readOnly class="col-sm-8" placeholder="Total Amount" aria-label="Invoice setup" aria-describedby="basic-addon2" 
            value={data?.TotalAmount} />
        </div>
        <div class="input-group mb-1 row">
          <span class="input-group-text col-sm-4" id="inv-unitprice">Unit Price </span>
          <input type="text" readOnly class="col-sm-8" placeholder="Unit Price" aria-label="Invoice setup" aria-describedby="basic-addon2" 
            value={data?.CalculatedUnitPrice} />
        </div>

        {/* <div class="input-group mb-1 row">
          <span class="input-group-text col-sm-4" id="inv-emailed">Emailed </span>
          <input type="text" class="form-control col-sm-8" placeholder="Email address" aria-label="Invoice setup" aria-describedby="basic-addon2" 
            value={data?.Emailed} onChange={e=>handleValueChange("Emailed", e.target.value)}/>
        </div> */}
        <div class="input-group mb-1 row">
          <span class="input-group-text col-sm-4" id="inv-note">Note </span>
          <input type="text" class="form-control col-sm-8" placeholder="Notes" aria-label="Invoice setup" aria-describedby="basic-addon2" 
            value={data?.Note} onChange={e=>handleValueChange("Note", e.target.value)}/>
        </div>
      
        <div class="input-group mb-1 align-items-center row">
          <span class="input-group-text col-sm-4" id="inv-locked">Locked </span>
          <input class="form-check-input mx-2" type="checkbox" style={{ border: '1px solid darkgrey' }} aria-label="Invoice setup" aria-describedby="basic-addon2" 
            checked={data?.Locked} onChange={e=>handleValueChange("Locked", e.target.checked)}/>
        </div>
        <div class="input-group mb-1 align-items-center row">
          <span class="input-group-text col-sm-4" id="inv-completed">Completed </span>
          <input class="form-check-input mx-2" type="checkbox" style={{ border: '1px solid darkgrey' }} aria-label="Invoice setup" aria-describedby="basic-addon2" 
            checked={data?.Completed} onChange={e=>handleValueChange("Completed", e.target.checked)}/>
        </div>      
      </Modal.Body>
      {/* <ReactJson src={{data}} /> */}
      <Modal.Footer>
        <Button variant="secondary" onClick={calculatePrice}>Calculate</Button>
        <Button variant="primary" onClick={handleSubmit} disabled={recalculate}>Submit</Button>
        <Button variant="secondary" onClick={handleCancel}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  )
}

const ViewInvoiceModal = ({invoice, show, setShow, email, refresh}) => {

  const onHide = () => setShow(false)
  const handleCancel = () => {
      onHide()
  }

  const handleSendEmail = () =>  {
    // When the email button is clicked, trigger backend to send the PDF
    axios.post(dvSrv + `/api/invoice/${invoice.ID}/email`, 
      {Email: email, },// Email address to send the PDF to}
      {withCredentials: true})
    .then(({data}) => {
      toast.success("Email is successfully sent")
      onHide()
      refresh()
    })
    .catch(error => { 
      console.error("Error: ", error) 
      toast.error(error.response?.data?.error)
    } )    
    
   }

  return (
    <Modal show={show} onHide={onHide} size="lg" centered={true}
      backdrop="static" // This disables closing by clicking the backdrop.
      keyboard={false}  // This disables closing by pressing the Escape key.
    >

      <Modal.Header closeButton>
        <Modal.Title>Deepvin Invoice {invoice.Name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InvoicePDFViewer invoice={invoice} />
      </Modal.Body>
      {/* <ReactJson src={{data}} /> */}
      <Modal.Footer>
        <span class="me-1">Email to: </span>
        <input type="text" readOnly class="me-2" placeholder="Email to" value={email} />
        <Button variant="primary" onClick={handleSendEmail} disabled={email === null || email === ""}>Send</Button>
        <Button variant="secondary" onClick={handleCancel}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  )
}

// Deepvin Only!
export const AccountLink = ({id}) => {
  const session = useContext(SessionContext)
  // const {accounts} = useContext(AccountsContext)
  const [accounts, setAccounts] = useState([])
  useEffect(() => {
    axios.get(dvSrv + `/json/account/`, {withCredentials: true})
    .then(({data}) => setAccounts(data))
    .catch(error => {
      console.log('Error:', error);
      if (error.response && error.response.status === 401) {
          session.setData(null)
      } else {console.error("Error: ", error)}
  })}, [])
  const acct = accounts.find(a => a.ID === id)
  return <Link to={"/account/"+id}>{acct?.Name}</Link>
}

export const AccountSelector = ({id, handleChange, accounts}) => {
  // const {accounts} = useContext(AccountsContext)
  if (accounts?.length > 0)
  return (
    <select class="form-select"
      name="AccountID"
      value={id}
      onChange={handleChange}
    >
      <option value="">Select an account</option>
      {accounts.map(acct =>
      <option value={acct.ID}>{acct.Name}</option>)}
    </select>
  )
}

export const DistributorSelector = ({distributor, handleChange}) =>
  <select class="form-control" 
    name="DistributorID" 
    value={distributor}
    onChange={handleChange}
  >
    <option value="">Distributor ...</option>
    <option value="a0bf67e6-c3d9-a28c-00de-5d3608615302">UE Medical</option>
    <option value="a528ee7b-c0ce-4118-7be0-5c8a83460c2e">Bound Tree Medical</option>
  </select>

// Set the worker for PDF.js
//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
//pdfjs.GlobalWorkerOptions.workerSrc = workerSrc

export const InvoicePDFViewer = ({invoice}) => {
  const [pdfData, setPdfData] = useState(null);
  const [numPages, setNumPages] = useState(null);
  //const [emailSent, setEmailSent] = useState(false);

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  // Fetch the generated PDF from the server
  useEffect(() => {

    axios.get(dvSrv + `/api/invoice/${invoice.ID}/pdf`, 
      {responseType: 'arraybuffer' , withCredentials: true})
    .then(({data}) => {
      const pdfBlob = new Blob([data], { type: 'application/pdf' })
      const pdfUrl = URL.createObjectURL(pdfBlob);
      setPdfData(pdfUrl); // Use the URL to display the PDF
    })
    .catch(error => { 
      console.error("Error: ", error) 
      toast.error(error.response?.data?.error)
    } )    

  }, [])

  return (
    <div style={{
      height: '100%', // Make sure the viewer container takes full height
      }}
    >
      {pdfData ? (
          <Worker /*workerUrl={workerSrc}*/>
              <Viewer fileUrl={pdfData} 
                plugins={[defaultLayoutPluginInstance]} 
                defaultScale={1} // Set zoom to 100%
              />
          </Worker>
      ) : (
        <p>Loading Invoice...</p>
      )}
    </div>
  )
}
